import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MainText from '../components/main-text';

const About = ({ data }) => {
  const { markdownRemark } = data;
  const { html } = markdownRemark;
  return (
    <Layout>
      <SEO title="Iskolánkról" />
      <MainText about dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/about/" }) {
      html
    }
  }
`;

export default About;
